"use client";

import {Button, Card, CardBody, Chip, Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography} from "@material-tailwind/react";
import Background from "@/images/citypipes.webp";
import {  useRouter } from "next/navigation";
import {HiDocumentText, HiOutlineCreditCard, HiOutlineDocumentText, HiOutlineLockClosed, HiOutlinePencilAlt, HiOutlineSearchCircle, HiOutlineShieldCheck, HiOutlineTicket, HiOutlineViewGridAdd, HiPuzzle} from "react-icons/hi";
import ContactUsForm from "@/components/ContactUsForm";
import React, {useEffect, useState} from "react";
import {Cog6ToothIcon, Square3Stack3DIcon, UserCircleIcon} from "@heroicons/react/16/solid";


export default function Home() {
    const router = useRouter()

    const [CurrentTab, SetCurrentTab] = useState("property-management");

    const allFeatures = [
        {
            icon: <HiDocumentText />,
            title: "Automated Accurate Leasing",
            description:
                "Enable 24/7 leasing with our streamlined process. Tenants can apply, sign contracts, and pay deposits in just 3 minutes, securing leases instantly.",
            chips:[
                {text:"24/7", color:"green"},

            ],
            tab:"property-management"
        },
        {
            icon: <HiOutlineSearchCircle />,
            title: "Automated Tenant Screening",
            description:
                "Integrated with TransUnion SmartMove, our platform simplifies and automates tenant screening. Providing fast, reliable background checks and returning all information back to your leasing dashboard for your quick review.",
            chips: [
                {text:"Private Beta", color: "blue"}
            ],
            tab:"property-management"
        },
        {
            icon: <HiDocumentText />,
            title: "Dynamic Accurate Contracts",
            description:
                "Generate 100% accurate, asset-specific contracts in seconds. Every lease is customized with all necessary addendums, eliminating errors and ensuring compliance.",

            tab:"property-management"
        },
        {
            icon: <HiPuzzle />,
            title:"Leasing Dashboard",
            description: "Manage every aspect of the leasing process from a single, intuitive dashboard. Track applications, approve or deny requests, and access all related documents easily.  One employee can do the work of 15.",
            tab:"property-management"
        },
        {
            icon: <HiPuzzle />,
            title: "Connected Public Website",
            description: "Your online hub for live availability, applications, and maintenance requests. Advertising available units, seamlessly integrates with Rental Flow, ensuring up-to-date information for tenants and prospects.",
            tab:"tenant"
        },
        {
            icon: <HiPuzzle />,
            title: "User-Friendly Tenant Portal",
            description: "Empower tenants with a streamlined portal for signing leases, submitting maintenance requests, and managing payments. Reduce calls and enhance satisfaction with self-service options.",
            tab:"tenant"
        },
        {
            icon: <HiPuzzle />,
            title: "Custom Integration",
            description: "Tailor our platform to your existing systems. Rental Flow offers seamless integration with your current website and external software solutions for a unified experience.",
            tab:"property-management"
        },
        {
            icon: <HiPuzzle />,
            title: "Custom Workflows",
            description: "Automate and streamline your unique business processes. Our platform adapts to your refined workflows, improving efficiency and reducing manual effort.  Automate inspections, turnover processes, parking purchases and much, much more!",
            tab:"property-management"
        },
        {
            icon: <HiPuzzle />,
            title: "Secure Flexible Online Payments",
            description: "Accept ACH (with instant verification!) and credit card payments with robust security features. Our platform ensures your transactions are safe, simple, and convenient for all users.",
            tab:"tenant"
        },
        {
            icon: <HiPuzzle />,
            title: "Business Intelligence (BI) Tools",
            description: "Unlock the power of your data with our BI tools. Gain actionable insights, track efficiencies, predict trends, and create custom reports to drive smarter business decisions.",
            tab:"property-management"
        },
        {
            icon: <HiPuzzle />,
            title: "Maintenance Tickets with Custom Flows",
            description: "Simplify maintenance management with customizable ticket flows. Our system ensures timely responses and efficient resolution of tenant issues.  Turn damage check boxes on forms into multiple tickets with ease.",
            tab:"property-management"
        },
        {
            icon: <HiPuzzle />,
            title: "Smart Locks and Cameras Integration",
            description: "Transform property security with integrated Zentra smart locks and Eagle Eye cameras. Our platform automates access control and surveillance providing enhanced peace of mind for tenants and property managers alike.",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "Smart Locks - Zentra Integration",
            description: "Experience keyless convenience with Zentra smart locks, seamlessly integrated with Rental Flow. Enjoy secure, easy-to-retrofit locks where internet access is optional!  Cloud managed and user-friendly.  Never rekey at turnover again!",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "Security Cameras (Eagle Eye Integration)",
            description: "Enhance property safety with Eagle Eye’s advanced camera systems. Fully integrated with Rental Flow, offering real-time monitoring and seamless cloud-based video management.",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "Central Cloud Management",
            description: "Control access, monitor usage and manage multiple locks from an intuitive cloud portal. Zentra locks offer advanced security features such as encryption and tamper detection.",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "Keyless Access Methods",
            description: "Eliminate rekeying at turnover. Locks can securely be accessed by Mobile Apps, Digital Wallets (NFC), Fobs, Number Pads, & Keys",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "No Internet Required",
            description: "Locks can be managed & provisioned without internet.  Wireless or cabling enables realtime access control, but no tour allows for configurations to be exchanged from smart devices. ",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "Ease of Retrofit",
            description: "Designed to be retrofitted to existing buildings.  Install a smart lock in as little as 5 minutes. No internet requirement means no cabling to the frame or wifi networks to rely on. Minimal cost of installation and minimal construction mess.",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "Reliable Hardware",
            description: "Schlage is known for high quality, secure, durable and reliable locks. We carry multiple smart lock styles & designs are available including: deadbolts, tubular, lever, & mortise.",
            tab:"access-control"
        },
        {
            icon: <HiPuzzle />,
            title: "MultiFamily Properties",
            description: "Convenient access methods, enhanced tenant experience, efficient remote management, no rekeying at turnover and emergency access override are just a few features that benefit property managers and tenants.",
            tab:"access-control"
        }
    ];







    const Features = allFeatures;

    function FeatureCard({ icon, title, chips, children  }) {
        return (
            <Card color="transparent" shadow={false} className={"animate-fade"}>
                <CardBody className="grid justify-center text-center">
                    <div className="mx-auto mb-6 grid h-12 w-12 place-items-center rounded-full bg-gray-900 p-2.5 text-white shadow">
                        {icon}
                    </div>
                    <Typography variant="h5" color="blue-gray" className="mb-2">
                        {title ?? "TBD"}
                    </Typography>
                    <div className={"flex justify-center gap-1"}>
                        {chips?.map( (row,id) => {
                            return(
                                <Chip key={id} color={row.color} size="sm" className="rounded-full max-w-max"
                                      value={row.text} />
                            )
                        })}

                    </div>
                    <Typography className="font-normal !text-gray-500">
                        {children ?? "TBD"}
                    </Typography>
                </CardBody>
            </Card>
        );
    }




    useEffect(() => {
       // SetFeatures(allFeatures.sort(() => 0.5 - Math.random()).slice(0, 6))
    }, [])


    const features = [
        {
            icon: <HiDocumentText />,
            title: "Automated Accurate Leasing",
            description:
                "Enable 24/7 leasing with our streamlined process. Tenants can apply, sign contracts, and pay deposits in just 3 minutes, securing leases instantly.",
            chips:[
                {text:"24/7", color:"green"},

            ]
        },
        {
            icon: <HiOutlineSearchCircle />,
            title: "Automated Tenant Screening",
            description:
                "Integrated with TransUnion SmartMove, our platform simplifies and automates tenant screening. Providing fast, reliable background checks and returning all information back to your leasing dashboard for your quick review.",
            chips: [
                {text:"Coming Soon", color: "orange"}
            ]
        },
        {
            icon: <HiDocumentText />,
            title: "Dynamic Accurate Contracts",
            description:
                "Generate 100% accurate, asset-specific contracts in seconds. Every lease is customized with all necessary addendums, eliminating errors and ensuring compliance.",
        },


        {
            icon: <HiPuzzle />,
            title: "Custom Integration",
            description:
                "Integrate our data with your existing website or external software solutions.",
        },

        {
            icon: <HiOutlineTicket />,
            title: "Ticket flows",
            chips: [
                {text:"Coming Soon", color: "orange"}
            ],
            description:
                "Enable any kind of ticketing and flows, from Maintenance ticketing to support.  We enable tickets to follow custom flows to help you ensure high tenant satsifaction.",
        },
        {
            icon: <HiOutlineViewGridAdd />,
            title: "Connected Tenant Portal",
            chips: [
                {text:"Site Analytics", color: "green"}
            ],
            description:
                "Reduce tenant calls through our streamlined tenant portal. Tenants sign applications, file maintenance tickets and manage autopay.",
        },


    ];

    const data = [
        {
            label: "Property Management",
            value: "property-management",
            icon: Square3Stack3DIcon,

        },
        {
            label: "Tenant",
            value: "tenant",
            icon: UserCircleIcon,

        },
        {
            label: "Access Control",
            value: "access-control",
            icon: Cog6ToothIcon,

        },
    ];

  return (
    <main >
        <div>
            <section>
                <div
                    style={{
                        background: `linear-gradient(to bottom, rgba(0, 85, 212, .70), rgba(135, 170, 222, .60) ) , url(${Background.src})`,
                        width: '100%',
                        height: '100%',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    className="	pb-5 pt-5"
                >
                    <div className="grid min-h-full h-full px-8   ">
                        <div className="container relative z-10 w-75 my-auto mx-auto grid place-items-center text-center">
                            <Typography
                                variant="h1"
                                color="white"
                                className="my-4 text-3xl !leading-snug lg:text-5xl"
                            >
                                Rent with the Flow!
                            </Typography>
                            <Typography
                                variant="h4"
                                color="white"
                                className="mb-10 w-full md:max-w-full lg:max-w-xl"
                            >
                                Automating Property Management & Leasing
                            </Typography>
                            <Typography
                                variant="h5"
                                color="white"
                                className="hidden mb-10 w-full md:max-w-full lg:max-w-xl"
                            >
                                Reduce Overhead. Increase Occupancy. Increases Client Satisfaction
                            </Typography>
                            <Button className={"mb-10 w-52"} fullWidth={true} style={{backgroundColor: "#FF6600"}} onClick={() => router.push("/contact")}>Book a Demo!</Button>

                            {/*<div className="container mx-auto">*/}
                            {/*  <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-3">*/}
                            {/*    {stats.map((props, key) => (*/}
                            {/*      <StatsCard key={key} {...props} />*/}
                            {/*    ))}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-10 px-4">
                <div className="container mx-auto mb-5 text-center lg:mb-5">
                    <Typography
                        color="blue-gray"
                        className="mb-4 text-3xl !leading-tight lg:text-4xl"
                    >
                        Automate Leasing. Accelerate Growth!
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-9/12 lg:px-8"
                    >
                        Reduce Overhead | Increase Occupancy | Increases Client Satisfaction
                    </Typography>
                </div>


                <Tabs value={CurrentTab} >
                    <TabsHeader className={"bg-gray-900"} indicatorProps={{ className: "bg-rf-orange pingIcon" }}>
                        {data.map(({ label, value, icon }) => (
                            <Tab   key={value} value={value} onClick={()=> SetCurrentTab(value)}>
                                <div className="flex items-center gap-2 text-white">
                                    {React.createElement(icon, { className: "w-5 h-5" })}
                                    {label}
                                </div>
                            </Tab>
                        ))}
                    </TabsHeader>

                </Tabs>


                <div className="container mx-auto grid grid-cols-1 gap-y-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-y-12 min-h-20 transition-opacity ">
                    {Features?.filter(x=>x.tab === CurrentTab).map(({icon, title, chips, description}, id) => (
                        <FeatureCard key={id} icon={icon} title={title} chips={chips ?? []}>
                            {description}
                        </FeatureCard>
                    ))}
                </div>
            </section>

            {/*<section className={"pt-10"}>*/}
            {/*    <div className="lg:space-y-24 space-y-12">*/}
            {/*        <div className="grid grid-cols-1 items-center place-items-center gap-y-16 lg:grid-cols-2">*/}
            {/*            <div className="h-min-[250px] lg:h-[480px] text-center flex flex-col justify-center lg:max-w-md mx-auto rounded-2xl bg-gray-900 p-8">*/}

            {/*                <Typography variant="h4" color="white">*/}
            {/*                    Automating Property Management*/}
            {/*                </Typography>*/}
            {/*                /!*<img src={AutoPropMgmt.src} className={"bg-image p-2 rounded-2xl"} style={{height: "20vh"}}/>*!/*/}
            {/*                <Typography color="white" className="mt-2 text-base font-normal">*/}
            {/*                    With over 10 years of experience in automating property management, we have built a suite of tools to improve the automation around property management!*/}
            {/*                </Typography>*/}
            {/*            </div>*/}
            {/*            <div className="flex flex-col gap-8">*/}
            {/*                <Option icon={HiOutlinePencilAlt} title="Automated Accurate Leasing">*/}
            {/*                    <div className={"flex justify-left gap-1"}>*/}
            {/*                        <Chip color={"green"} size="sm" className="rounded-full max-w-max" value={"24/7"}/>*/}
            {/*                        <Chip color={"green"} size="sm" className="rounded-full max-w-max" value={"Easy as 1-2-3"}/>*/}
            {/*                    </div>*/}

            {/*                    Enable 24/7 leasing with our streamlined process. Tenants can apply, sign contracts, and pay deposits in just 3 minutes, securing leases instantly.*/}
            {/*                </Option>*/}
            {/*                <Option icon={HiOutlineDocumentText} title="Dynamic Accurate Contracts">*/}
            {/*                    Generate 100% accurate, asset-specific contracts in seconds. Every lease is customized with all necessary addendums, eliminating errors and ensuring compliance.*/}
            {/*                </Option>*/}
            {/*                <Option icon={HiOutlineShieldCheck} title="Automated Tenant Screening">*/}
            {/*                    <div className={"flex justify-left gap-1"}>*/}
            {/*                        <Chip color={"orange"} size="sm" className="rounded-full max-w-max" value={"Coming Soon!"}/>*/}
            {/*                    </div>*/}
            {/*                    Integrated with TransUnion SmartMove, our platform simplifies and automates tenant screening. Providing fast, reliable background checks and returning all information back to your leasing dashboard for your quick*/}
            {/*                    review.*/}
            {/*                </Option>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="grid grid-cols-1 items-center place-items-center gap-16 lg:grid-cols-2">*/}
            {/*            <div className="lg:row-auto row-start-2 flex flex-col gap-8">*/}
            {/*                <Option icon={HiOutlineTicket} title="Ticket Flows">*/}
            {/*                    Enable any kind of ticketing and flows, from Maintenance ticketing to support. We enable tickets to follow custom flows to help you ensure high tenant satisfaction.*/}
            {/*                </Option>*/}
            {/*                <Option icon={HiOutlineCreditCard} title="Secure Payments">*/}
            {/*                    <div className={"flex justify-left gap-1"}>*/}
            {/*                        <Chip color={"green"} size="sm" className="rounded-full max-w-max" value={"ACH"}/>*/}
            {/*                        <Chip color={"green"} size="sm" className="rounded-full max-w-max" value={"Credit"}/>*/}
            {/*                        <Chip color={"green"} size="sm" className="rounded-full max-w-max" value={"Debit"}/>*/}

            {/*                    </div>*/}
            {/*                    Accept ACH and Credit Card payments while ensuring security and instant verification of the linked account.*/}
            {/*                </Option>*/}
            {/*                <Option icon={HiOutlineLockClosed} title="Locks & Cameras">*/}
            {/*                    Never rekey at turnover again, rental flow provides smart locks that automatically provision stays. No more keys! Camera systems are fully integrated as well!*/}
            {/*                </Option>*/}
            {/*            </div>*/}
            {/*            <div className="h-min-[250px] lg:h-[480px] text-center flex flex-col justify-center lg:max-w-md mx-auto rounded-2xl bg-gray-900 p-14">*/}
            {/*                <Typography variant="h3" color="white">*/}
            {/*                    Tenant Connect Portal*/}
            {/*                </Typography>*/}
            {/*                /!*<img src={TenantPortal.src} className={"bg-image p-2 rounded-2xl"} style={{height: "20vh"}}/>*!/*/}

            {/*                <Typography*/}
            {/*                    color="white"*/}
            {/*                    className="mt-2 text-base font-normal"*/}
            {/*                >*/}
            {/*                    Automating property management also requires interfacing with tenants, through our connect portal, we enable custom domains for your tenants to log in to manage and pay their leases.*/}
            {/*                </Typography>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section>
                <div className={"mx-auto p-5 lg:max-w-5xl"}>
                    <ContactUsForm/>
                </div>
            </section>
        </div>
    </main>
  );
}
